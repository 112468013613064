<template>
    <div>
		<el-form ref="queryForm" class="query-box"  v-model="queryParams" :inline="true">
			<el-form-item :label="translate('AUTHORITY.GROUP_CODE')" prop="groupCode" label-width="120px">
				<el-select v-model="queryParams.groupCode"
						   :placeholder="translate('AUTHORITY.GROUP_CODE')" clearable size="small" style="width: 240px">
					<el-option v-for="(value,key) in dicts.PERMISSION_GROUP" :key="key" :label="value" :value="value"/>
				</el-select>
			</el-form-item>
			<el-form-item :label="translate('AUTHORITY.CODE')" prop="code" label-width="120px">
				<el-input v-model="queryParams.code"
						  :placeholder="translate('FROM.PLACEHOLDER', {param: 'AUTHORITY.CODE'})" clearable
						  style="width: 240px"
						  size="small" @keyup.enter.native="handleQuery"/>
			</el-form-item>
			<el-form-item :label="translate('AUTHORITY.NAME')" prop="name" label-width="120px">
				<el-input v-model="queryParams.name"
						  :placeholder="translate('FROM.PLACEHOLDER', {param: 'AUTHORITY.NAME'})" clearable
						  style="width: 240px"
						  size="small" @keyup.enter.native="handleQuery"/>
			</el-form-item>
			<el-form-item :label="translate('AUTHORITY.MENU_ID')" prop="menuId" label-width="120px">
				<el-input v-model="queryParams.menuId"
						  :placeholder="translate('FROM.PLACEHOLDER', {param: 'AUTHORITY.MENU_ID'})" clearable
						  style="width: 240px"
						  size="small" @keyup.enter.native="handleQuery"/>
			</el-form-item>
			<el-form-item :label="translate('AUTHORITY.TYPE')" prop="type" label-width="120px">
				<el-select v-model="queryParams.type"
						   :placeholder="translate('AUTHORITY.TYPE')" clearable size="small" style="width: 240px">
					<el-option v-for="(value,key) in dicts.PERMISSION_TYPE" :key="key" :label="value" :value="value"/>
				</el-select>
			</el-form-item>
		</el-form>

		<el-row :gutter="10" class="mb8">
			<el-col :span="1.5">
				<el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">{{translate('OPERATION.QUERY')}}</el-button>
				<el-button icon="el-icon-refresh" size="mini" @click="handleResetQuery">{{translate('OPERATION.RESET')}}</el-button>
				<el-button type="warning" icon="el-icon-download" size="mini" @click="handleExport" v-permission="'SYS_AUTHORITY_EXPORT'">{{translate('OPERATION.EXPORT')}}</el-button>
			</el-col>
		</el-row>

	</div>
</template>

<script>
	import {UserApi} from '@/api';
    export default {
        name: "ToolBar",
		components: {},
		props: {
			dicts: {
				type: Object,
				default: {}
			}
		},
		data() {
			return {
				queryParams: {},
				createDateRange: [],
				updateDateRange: []
			}
		},
		mounted() {
		},
		methods: {
			handleQuery() {
				this.commitChange();
			},
			handleResetQuery(){
				this.queryParams = {};
				this.createDateRange = [];
				this.updateDateRange = [];
				this.commitChange();
			},
			handleExport() {
				this.$confirm(this.translate('FROM.CONFIRM_EXPORT', {module: 'MODULE.AUTHORITY'}), this.translate('FROM.WARNING'), {
					confirmButtonText: this.translate('FROM.SUBMIT'),
					cancelButtonText: this.translate('FROM.CANCEL'),
					type: "warning"
				}).then(async () => {
					this.dealWithQueryParams();
					let result = (await UserApi.authority.export(this.queryParams));
					if (result.success) {
						this.downloadFile(result.data);
					}
				});
			},
			dealWithQueryParams() {
				let [startCreateTime, endCreateTime] = this.createDateRange;
				this.queryParams.startCreateTime = startCreateTime && (startCreateTime + ' 00:00:00');
				this.queryParams.endCreateTime = endCreateTime && (endCreateTime + ' 23:59:59');

				let [startUpdateTime, endUpdateTime] = this.updateDateRange;
				this.queryParams.startUpdateTime = startUpdateTime && (startUpdateTime + ' 00:00:00');
				this.queryParams.endUpdateTime = endUpdateTime && (endUpdateTime + ' 23:59:59');
			},
			commitChange() {
				this.dealWithQueryParams();
				let params = {...this.queryParams};
				this.$emit('on-change', params);
			},
		}
    }
</script>

<style scoped>

</style>
